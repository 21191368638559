<template>
	<div class="pageContainer">
		<web-header></web-header>
		<div class="content">
			<router-title :menuList="menuList[0]" :list="menuList[1]" />
			<div class="live_list">
				<div class="live_item" v-for="(item,index) in list" :key="index" @click="toDetails(item.id)">
					<div class="item_l">
						<img :src="item.surfacePlot" alt="">
					</div>
					<div class="item_r">
						<div class="name">{{item.name}}</div>
						<div class="people">
							<img src="../../assets/live/icon_finished.png" alt="" width="16">
							<span v-if="item.status == 1">{{item.viewPeople}}人在线</span>
							<span v-else>{{item.bookedPeople}}人预约</span>
						</div>
						<div class="time">{{item.beginTime}}</div>
						<div class="btn pay_btn" v-if="!item.stuToLive" @click.stop="toYuyue(item)">
							<span>立即预约</span>
							<i class="el-icon-arrow-right"></i>
						</div>
						<div class="btn" v-if="item.stuToLive && item.status == 0 ">
							<span>已预约</span>
						</div>
						<div class="btn reserve_btn" v-if="item.stuToLive && item.status == 1 "
							@click.stop="watchInfo(item.id)">
							<span>正在直播</span>
							<i class="el-icon-arrow-right"></i>
						</div>
						<div class="btn" v-if="item.status === 2">
							<span>已结束</span>
						</div>
					</div>
				</div>
			</div>
			<div class="pagination">
				<el-pagination background layout="prev, pager, next" :page-size="pageSize"
					@current-change="handleCurrentChange" :page-count="count">
				</el-pagination>
			</div>
		</div>
		<web-footer></web-footer>
	</div>
</template>

<script>
	import WebFooter from '../../components/webFooter.vue'
	import webHeader from '../../components/webHeader.vue'
	import RouterTitle from "../../components/routerTitle.vue";
	import {
		mapState
	} from 'vuex';
	export default {
		components: {
			webHeader,
			WebFooter,
			RouterTitle
		},
		data() {
			return {
				menuList: ["首页", "直播课堂"],
				list: [

				],
				pageSize: 8,
				pageNumber: 1,
				count: 0
			}
		},
		computed: {
			...mapState(['isLogin'])
		},
		created() {
			this.getByPage()
		},
		methods: {
			//去直播详情
			watchInfo(id) {
				if (!this.isLogin) return this.$message.error('请先登录')
				this.$router.push({
					path: '/liveInfo',
					query: {
						id
					}
				})
			},
			getByPage() {
				let data = {
					pageSize: this.pageSize,
					pageNumber: this.pageNumber
				}
				this.$http.post('liveGetByPage', data).then((res) => {
					if (res.code == 200) {
						this.list = res.data.records
						this.count = res.data.pages
					} else {
						this.$message.error(res.message);
					}
				})
			},
			toDetails(id) {
				this.$router.push({
					path: '/livedetails',
					query: {
						id
					}
				})
			},
			toYuyue(item) {
				if (!this.isLogin) return this.$message.error('请先登录')
				// 保存id，防止刷新
				localStorage.setItem('cousreId', item.id)
				this.$router.push({
					path: '/trainingProgram/paymentOrder',
					query: {
						index: 4,
						type: 1,
						data: item
					}
				})

				// if (!this.isLogin) return this.$message.error('请先登录')

			},
			handleCurrentChange(e) {
				this.pageNumber = e
				this.getByPage()
			}
		}
	}
</script>

<style lang="less" scoped>
	.live_list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.live_item {
			width: 48%;
			margin-bottom: 54px;
			display: flex;
			cursor: pointer;

			.item_l {
				width: 273px;
				height: 210px;
				border-radius: 30px;

				img {
					width: 100%;
					height: 100%;
					border-radius: 30px;
				}
			}

			.item_r {
				flex: 1;
				margin-left: 21px;

				.name {
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #333333;
					line-height: 24px;
					max-height: 48px;
					text-overflow: ellipsis;
					display: -webkit-box;
					overflow: hidden;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					margin-bottom: 34px;
				}

				.people {
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: 300;
					color: #FBAF25;
					margin-bottom: 27px;

					span {
						margin-left: 11px;
					}
				}

				.time {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 300;
					color: #999999;
					line-height: 24px;
					margin-bottom: 16px;
				}

				.btn {
					width: 149px;
					height: 44px;
					line-height: 44px;
					text-align: center;
					background: #EEEEEE;
					border-radius: 8px;
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #111111;
					cursor: default;
				}

				.pay_btn {
					cursor: pointer;
					background: #FBAF25;
				}

				.reserve_btn {
					cursor: pointer;
					background: #FB3E25;
					color: #FFFFFF;
				}
			}
		}
	}

	.content {
		width: 1200px;
		background: #F9F9F9;
		margin: auto;

		.list {
			.item {
				display: flex;
				margin-bottom: 30px;

				.item_l {
					width: 398px;
					height: 277px;

					.img {
						width: 100%;
						height: 100%;
					}
				}

				.item_r {
					margin-left: 28px;
					flex: 2;

					.info {
						display: flex;
						align-items: center;
						margin-bottom: 18px;

						.live_name {
							font-size: 20px;
							font-weight: bold;
							color: #333333;
							margin-right: 16px;
						}

						.live_class {
							background: rgba(164, 208, 255, .5);
							border-radius: 2px;
							font-size: 14px;
							color: #59AAFF;
							padding: 3px 4px;
							margin-left: 9px;
						}
					}

					.lecturer,
					.live_time {
						font-size: 16px;
						color: #666666;
						margin-bottom: 18px;
					}

					.training_introduction {
						margin-bottom: 18px;

						h3 {
							font-size: 16px;
							font-weight: bold;
							color: #E51E0B;
							margin-bottom: 19px;
						}

						.introduce {
							font-size: 16px;
							color: #333333;
							line-height: 24px;
							text-indent: 2em;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 3;
							overflow: hidden;
						}
					}

					.btn_row {
						display: flex;
						align-items: flex-end;

						.btn {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 151px;
							height: 53px;
							line-height: 53px;
							background: #EEEEEE;
							border-radius: 5px;
							font-size: 18px;
							font-weight: 500;
							color: #999999;
							cursor: pointer;

							span {
								margin-left: 15px;
							}

							img {
								width: 24px;
							}
						}

						.living_btn {
							background: #FBAF25;
							color: #ffffff;
						}

						.reserve_btn {
							background: #E51E0B;
							color: #ffffff;
						}

						.people_num {
							font-size: 16px;
							color: #08AD2D;
							margin-left: 20px;
						}
					}
				}
			}
		}

		// .list {
		// 	display: flex;
		// 	flex-wrap: wrap;

		// 	.listItem {
		// 		width: 356px;
		// 		margin-right: 10px;
		// 		box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);
		// 		margin-bottom: 45px;

		// 		.img {
		// 			width: 356px;
		// 			height: 181px;
		// 			cursor: pointer;
		// 		}

		// 		.title {
		// 			font-size: 18px;
		// 			padding: 0 10px;
		// 			margin-bottom: 19px;
		// 			font-weight: 500;
		// 			color: #333333;
		// 			overflow: hidden;
		// 			text-overflow: ellipsis;
		// 			white-space: nowrap;
		// 		}

		// 		.time {
		// 			font-size: 18px;
		// 			padding: 0 10px;
		// 			margin-bottom: 38px;
		// 			font-weight: 400;
		// 			color: #999999;
		// 		}

		// 		.yuyue {
		// 			padding: 0 10px;
		// 			display: flex;
		// 			justify-content: space-between;
		// 			margin-bottom: 20px;

		// 			.bnt1 {
		// 				background: #E51E0B;
		// 				color: #F9F9F9;
		// 			}

		// 			.bnt2 {
		// 				background: #FBAF25;
		// 				color: #F9F9F9;
		// 			}

		// 			.bnt3 {
		// 				background: #EEEEEE;
		// 				color: #999999;
		// 			}
		// 		}
		// 	}
		// }

		.pagination {
			width: 100%;
			margin-bottom: 85px;
			text-align: center;

			/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
				background: #E51E0B;
			}
		}
	}

	.noTeacter {
		// margin-left: 45%;
		color: #666;
		height: 60vh;
		text-align: center;
		margin-top: 200px;
		font-size: 14px;
		color: #ccc;

		.noTeacter_img {
			img {
				width: 188px;
				height: 141px;
				margin-bottom: 10px;
			}
		}
	}
</style>
